1<template>
  <div class="body">
    <div class="zwnr">
      <div class="flex title">
        <div class="fwxx">发文信息</div>
        <div><a-button class="font" @click="clear_form">清空</a-button></div>
      </div>
      <div class="line"></div>
      <div style="margin-top: 20px">
        <a-form :form="form" class="">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item label="公文标题" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  placeholder="请输入"
                  v-decorator="['biaoti', { rules: [{ required: true, message: '请输入公文标题！' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="发文字号" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input placeholder="请输入" v-decorator="['documentNumber', { initialValue: '默认' }]" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="公文密级程度" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-select placeholder="请选择" v-decorator="['miji', {}]">
                  <a-select-option v-for="(item, index) in flow_secrecy_degree" :key="index" :value="item.code">{{
                    item.value
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="公文紧急程度" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-select placeholder="请选择" v-decorator="['jjcd', {}]">
                  <a-select-option v-for="(item, index) in flow_urgency_degree" :key="index" :value="item.code">{{
                    item.value
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="公文类型" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-select placeholder="请选择" v-decorator="['xzfn', {}]">
                  <a-select-option v-for="(item, index) in flow_file_type" :key="index" :value="item.code">{{
                    item.value
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="外部会稿单位/备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input placeholder="请输入" v-decorator="['outDraftDesc', {}]" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="外部会稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input placeholder="请输入" v-decorator="['outDraftDesc', {}]" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="会稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input placeholder="请输入" v-decorator="['outDraftDesc', {}]" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="会稿单位/备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input placeholder="请输入" v-decorator="['outDraftDesc', {}]" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="拟稿单位" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  v-show="false"
                  v-decorator="['ngdw_list', { rules: [{ required: true, message: '请选择！' }] }]"
                />
                <div class="">
                  <div v-for="(o, i) in ngdw_list" :key="'ngdw_list' + i" class="my-item">
                    <a-tag class="tags flex0">
                      <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                      <a-button type="link" icon="close" @click="ngdw_list.splice(i, 1)" />
                    </a-tag>
                  </div>
                  <a-button slot="suffix" icon="user" @click="showSelctDialog('ngdw_list', 1)"></a-button>
                </div>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="拟稿人" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  v-show="false"
                  v-decorator="['ngdw_list', { rules: [{ required: true, message: '请选择！' }] }]"
                />
                <div class="">
                  <div v-for="(o, i) in ngdw_list" :key="'ngdw_list' + i" class="my-item">
                    <a-tag class="tags flex0">
                      <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                      <a-button type="link" icon="close" @click="ngdw_list.splice(i, 1)" />
                    </a-tag>
                  </div>
                  <a-button slot="suffix" icon="user" @click="showSelctDialog('ngdw_list', 2)"></a-button>
                </div>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="核稿人" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input v-show="false" v-decorator="['checkIds', {}]" />
                <div class="">
                  <div v-for="(o, i) in checkIds" :key="'checkIds' + i" class="my-item">
                    <a-tag class="tags flex0">
                      <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                      <a-button type="link" icon="close" @click="checkIds.splice(i, 1)" />
                    </a-tag>
                  </div>
                  <a-button slot="suffix" icon="user" @click="showSelctDialog('checkIds', 2)"></a-button>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="主送" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                  v-show="false"
                  v-decorator="['mainIds', { rules: [{ required: true, message: '请选择！' }] }]"
                />
                <div class="">
                  <div v-for="(o, i) in mainIds" :key="'mainIds' + i" class="my-item">
                    <a-tag class="tags flex0">
                      <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                      <a-button type="link" icon="close" @click="mainIds.splice(i, 1)" />
                    </a-tag>
                  </div>
                  <a-button slot="suffix" icon="user" @click="showSelctDialog('mainIds', 2)"></a-button>
                </div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="抄送" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input v-show="false" v-decorator="['sendIds', {}]" />
                <div class="">
                  <div v-for="(o, i) in sendIds" :key="'sendIds' + i" class="my-item">
                    <a-tag class="tags flex0">
                      <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                      <a-button type="link" icon="close" @click="sendIds.splice(i, 1)" />
                    </a-tag>
                  </div>
                  <a-button slot="suffix" icon="user" @click="showSelctDialog('sendIds', 2)"></a-button>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <!-- <div class="bg-gray"></div>
    <div class="zwnr">
      <div class="flex title">
        <div class="fwxx">正文内容</div>
      </div>
      <div class="line" style="margin-bottom: 23px"></div>

      <editor :content.sync="content" style="margin-bottom: 20px" ref="editor" />
    </div> -->

    <div class="bg-gray"></div>
    <div class="zwnr">
      <div class="flex title">
        <div class="fwxx">附件内容</div>
        <div><a-button class="font" type="primary" ghost @click="empty">清空</a-button></div>
      </div>
      <div class="line"></div>
      <div class="margin-tt">
        <upload :fileIds.sync="fileIds" ref="upload" />
      </div>
      <div class="bts">
        <a-popconfirm
          placement="topRight"
          title="发布公文后公文不能修改，确定发送公文！"
          @confirm="() => handleSubmit(null, 2)"
        >
          <a-button type="primary"> 立即发布 </a-button>
        </a-popconfirm>
        <a-button type="primary" @click="handleSubmit($event, 1)" ghost> 存为草稿 </a-button>
      </div>
    </div>
    <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
  </div>
</template>

<script>
import { documentBillAdd, documentBillDetail, documentBillEdit } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { selectPerson } from '@/mixins'
import { vali_exist } from '@/utils/validator'
import editor from '@/views/fileStream/documentManage/editor.vue'
import upload from '@/views/fileStream/documentManage/upload.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
export default {
  components: { editor, upload, OpenData, selectPersonDialog },
  mixins: [selectPerson],
  props: {
    officialId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      labelCol: {
        sm: { span: 5 },
      },
      wrapperCol: {
        sm: { span: 19 },
      },
      form: this.$form.createForm(this),
      flow_secrecy_degree: [],
      flow_urgency_degree: [],
      flow_file_type: [],
      fileIds: [],
      content: '',
      ngdw_list: [],
      hgdw_list: [],
      wbhgdw_list: [], // 外部会稿单位
      checkIds: [],
      mainIds: [],
      sendIds: [],
      handler: vali_exist,
      type: '',
    }
  },
  watch: {
    ngdw_list(v) {
      this.form.setFieldsValue({ ngdw_list: v.toString() })
    },
    hgdw_list(v) {
      this.form.setFieldsValue({ hgdw_list: v.toString() })
    },
    wbhgdw_list(v) {
      this.form.setFieldsValue({ wbhgdw_list: v.toString() })
    },
    checkIds(v) {
      this.form.setFieldsValue({ checkIds: v.toString() })
    },
    mainIds(v) {
      this.form.setFieldsValue({ mainIds: v.toString() })
    },
    sendIds(v) {
      this.form.setFieldsValue({ sendIds: v.toString() })
    },
    officialId() {
      this.get_info()
    },
  },
  created() {
    this.getDict()
  },
  mounted() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.form_id = this.officialId
      const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          const { get_arr } = this
          const {
            documentNumber,
            outDraftDesc,
            name,
            urgencyDegree,
            secrecyDegree,
            draftId,
            fileType,
            documentFileList,
            content,
            meetId,
            outDraftId,
          } = res.data
          const form = {
            biaoti: name,
            jjcd: urgencyDegree ? String(urgencyDegree) : '',
            miji: secrecyDegree ? String(secrecyDegree) : '',
            userId: draftId,
            xzfn: fileType ? String(fileType) : '',
            fileIds: documentFileList,
            content,
            hgdw_list: meetId,
            wbhgdw_list: outDraftId,
            checkIds: res.data.checkIds,
            mainIds: res.data.mainIds,
            sendIds: res.data.sendIds,
            documentNumber,
            outDraftDesc,
          }
          //
          this.form.setFieldsValue(this.delEmptKey(form))
          //
          this.ngdw_list = this.formatDraftId(draftId)
          this.hgdw_list = this.formatDraftId(meetId)
          this.wbhgdw_list = this.formatDraftId(outDraftId)
          this.checkIds = this.formatDraftId(res.data.checkIds)
          this.mainIds = this.formatDraftId(res.data.mainIds)
          this.sendIds = this.formatDraftId(res.data.sendIds)
          //
          // this.$refs.editor.msg = content
          this.fileIds = documentFileList
        })
      }
    },
    /**
     * 获取字典数据
     */
    getDict() {
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        this.flow_secrecy_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
        this.flow_file_type = res.data
      })
    },

    clearMessage() {
      this.clear_form()
      this.empty()
      // this.$refs.editor.msg = ''
    },

    clear_form() {
      this.form.resetFields()
      this.ngdw_list = []
      this.hgdw_list = []
      this.wbhgdw_list = []
      this.checkIds = []
      this.mainIds = []
      this.sendIds = []
    },
    empty() {
      this.$refs.upload.empty()
    },
    choose_Persons(list, index) {
      this.selectPersonAndPart(list, index)
    },
    delEmptKey(obj) {
      const objCopy = { ...obj }
      const keys = Object.keys(objCopy)
      for (const index of keys) {
        if (!objCopy[index]) delete objCopy[index]
      }
      return objCopy
    },
    handleSubmit(e, status) {
      const { filter, content, fileIds, ngdw_list, hgdw_list, wbhgdw_list, checkIds, mainIds, sendIds } = this
      //
      let func = documentBillAdd
      const id = this.form_id
      if (id) {
        func = documentBillEdit
      }
      //
      e && e.preventDefault()
      this.form.validateFields((err, values) => {
        console.log('Received values of form: ', values)
        if (!err) {
          const obj = {
            name: values.biaoti,
            urgencyDegree: Number(values.jjcd) || '',
            secrecyDegree: Number(values.miji) || '',
            draftId: filter(ngdw_list),
            fileType: Number(values.xzfn) || '',
            fileIds,
            content,
            documentNumber: values.documentNumber,
            outDraftDesc: values.outDraftDesc,
            meetId: filter(hgdw_list),
            outDraftId: filter(wbhgdw_list),
            documentType: 2,
            checkIds: filter(checkIds),
            mainIds: filter(mainIds),
            sendIds: filter(sendIds),
            status,
          }
          //
          if (id) {
            obj.id = id
          }
          //
          func(this.delEmptKey(obj)).then((res) => {
            if (!res.success) {
              return
            }
            this.$message.success(res.message)
            this.$emit('close')
            //
          })
        }
      })
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
    },
    getIdKey(type) {
      return type === 'departmentName' ? 'departmentId' : 'userId'
    },
    filter(ids) {
      return JSON.stringify(
        ids.map(({ corpId, userId, id, type }) => ({
          [this.getIdKey(type)]: userId || id,
          corpId,
          type,
        }))
      )
    },
    get_arr(ids) {
      const arr = ids.split(',')
      const data = []
      arr.forEach((element) => {
        data.push({ userId: element })
      })
      return data
    },
  },
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.flex0 {
  display: flex;
  align-items: center;
}
.font {
  font-size: 16px;
}
.body {
  width: 1280px;
  margin: auto;
  background: #fff;

  .title {
    margin-top: 30px;
  }
  .line {
    height: 1px;
    border: 1px dashed #3b9afa;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .fwxx {
    font-size: 18px;
    font-weight: bold;
    color: #3e3d3d;
  }
  .fwxx::before {
    content: '';
    width: 4px;
    height: 20px;
    background: #3b9afa;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
::v-deep .ant-form-item-label {
  text-align: left;
}
.bg-gray {
  height: 10px;
  background: #ebedf0;
}
.zwnr {
  padding: 0 37px;
}
.schj {
  width: 310px;
  height: 58px;
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
}
.margin-tt {
  margin-top: 20px;
}
.bts {
  text-align: center;
  padding: 57px 0 64px 0;
  button {
    font-size: 16px;
    margin: 0 10px;
  }
}
.my-item {
  display: inline-block;
}
</style>